import React, { useEffect, useState } from 'react';
import { FormControl, Switch } from '@chakra-ui/react';
import { postSongStatus, settings } from '../api/api';


const RequestSongSwitch = () => {
  const [songRequestStatus, setSongRequestStatus] = useState(0);

  const handleSwitchChange = async () => {
    try {
      const newStatus = songRequestStatus == 0 ? 1 : 0;
      await postSongStatus(newStatus);
      setSongRequestStatus(newStatus);
    } catch (error) {
      console.error('Song request status güncelleme hatası:', error);
    }
  };

  useEffect(() => {
    const fetchSongStatus = async () => {
      try {
        const response = await settings();
        const status = response.song_request_status;
        setSongRequestStatus(status);
      } catch (error) {
        console.error('Song request status çekme hatası:', error);
      }
    };

    fetchSongStatus();
  }, []);

  return (

    <FormControl display='flex' alignItems='center' justifyContent='flex-end'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: `2px solid ${songRequestStatus === 1 ? 'teal' : 'red'}`,
          borderRadius: '18px',

        }}
      >
        <div
          style={{
            fontWeight: "bold",
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '8px',
          }}
        >
          <span style={{ margin: '0', fontSize: '20x' }}>
            {songRequestStatus == 1 ? 'İstek Açık' : 'İstek Kapalı'}
          </span>
        </div>

        <Switch
          size='lg'
          colorScheme='teal'
          id='song-request-switch'
          onChange={handleSwitchChange}
          isChecked={songRequestStatus == 1}
          marginRight="5px"
        />

      </div>
    </FormControl>

  );
};

export default RequestSongSwitch;
