import { Box, Flex, Container } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useMenu } from "../store/MenuContext";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import NavbarBottom from "../layout/NavbarBottom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useBasket } from "../store/BasketContext";
import { fetchProductList } from "../api/api";
import "../css/App.css";
import SideCategories from "../components/SideCategories";
import ProductCard from "../components/ProductCard";
import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";

function Product() {
  const { category_id } = useParams();
  const { products, setProducts } = useMenu();
  const { addToBasket } = useBasket();

  const notify = () =>
    toast("Ürün Sepete Eklendi!", {
      hideProgressBar: true,
      position: "top-left",
    });

  const { isLoading, error, data } = useQuery(["product", category_id], () =>
    fetchProductList(category_id)
  );

  useEffect(() => {
    setProducts(data);
  }, [data, setProducts]);

  const addBasketAll = (e, item) => {
    e.preventDefault();
    const basketNote = "";
    const checkedValues = "";
    if (
      item.description === null &&
      item.allergen === null &&
      item.quantity === null &&
      item.removed_material === null
    ) {
      addToBasket(item, basketNote, checkedValues);
      notify();
    }
  };

  if (isLoading) return <Loading height="100vh" />;
  if (error) return "An error has occurred: " + error.message;

  return (
    <Box>
      <Navbar />
      <Link to="/categories">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>

      <SideCategories />

      <Container mb={100}>
        {products?.map((product) => {
          return (
            <ProductCard
              key={product.id}
              item={product}
              addBasketAll={addBasketAll}
            />
          );
        })}
        <ToastContainer autoClose={2000} />
      </Container>
      <NavbarBottom />
    </Box>
  );
}

export default Product;
