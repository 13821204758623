import React, { useEffect, useState } from "react";
import "../css/NavbarBottom.css";
import { Link } from "react-router-dom";
import { useBasket } from "../store/BasketContext";
import { useTranslation } from "react-i18next";
import { waiterRequestSettings } from "../api/api";

function NavbarBottom() {
  const { basket } = useBasket();
  const { t } = useTranslation();
  const [waiterRequestStatus, setWaiterRequestStatus] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const data = await waiterRequestSettings();
        setWaiterRequestStatus(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <nav className="nav">
      <Link to="/" className="nav__link">
        <i className="material-icons nav__icon ">home</i>
        <span className="nav__text">{t("Anasayfa")}</span>
      </Link>
      <Link to="/categories" className="nav__link">
        <i className="material-icons nav__icon">dashboard</i>
        <span className="nav__text">{t("Menü")}</span>
      </Link>
      {waiterRequestStatus ? (
        <Link to="/waiter_calling" className="nav__link ">
          <i className="material-icons nav__icon">person</i>
          <span className="nav__text">{t("Garson")}</span>
        </Link>
      ) : (
        <a className="nav__link disabled" href="#">
          <i className="material-icons nav__icon">person</i>
          <span className="nav__text">{t("Garson")}</span>
        </a>
      )}

      <Link to="/basket" className="nav__link position-relative">
        <i className="material-icons nav__icon">shopping_basket</i>
        <span className="position-absolute top-0 basketcss translate-middle badge rounded-pill mt-2">
          {basket.length}
        </span>
        <span className="nav__text">{t("Sepet")}</span>
      </Link>
      <a className="nav__link disabled" href="#">
        {" "}
        <i className="material-icons nav__icon">assignment</i>
        <span color="black" className="nav__text">
          {t("Hesap")}
        </span>
      </a>
    </nav>
  );
}

export default NavbarBottom;
