import React, { useState } from "react";
import { Box, Button, Text, Image, Flex, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "../logo/kalyann_cafe.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./Loading";

const SideCategories = () => {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState();
  const [loading, setLoading] = useState(false);

  const categoryURL = `${process.env.REACT_APP_BASE_ENDPOINT}/api/category`;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCategoriesAllApi = () => {
    setLoading(true);
    axios.get(categoryURL).then((response) => {
      setCategories(response.data);
      setLoading(false);
    });
    handleShow();
  };

  if (loading) return <Loading height="100vh" />;
  return (
    <div>
      <Box>
        <Button
          ml={3}
          mt={3}
          mb={5}
          boxSize={8}
          fontSize="50px"
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          onClick={getCategoriesAllApi}
        ></Button>

        <Offcanvas
          className="offcanvasMenü"
          style={{ width: "350px" }}
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Box p="2px" backgroundColor="transparent" rounded="full">
                <Image
                  borderRadius="full"
                  height="115px"
                  rounded="full"
                  src={logo}
                  alt="menulogo"
                />
              </Box>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {loading ? (
              <Loading height="100vh" />
            ) : (
              <>
                {categories?.map((item, index) => {
                  return (
                    <Link key={index} to={`/product/${item.id}`}>
                      <Box color="white" borderBottom="solid 0.5px ">
                        <Flex
                          borderRadius={15}
                          onClick={() => setShow(false)}
                          opacity="2"
                          filter="alpha(opacity=60)"
                          cursor="pointer"
                          p={2}
                          mt={2}
                          key={index}
                          minH="48px"
                        >
                          {item.image !== null && (
                            <Image
                              boxSize="5rem"
                              borderRadius={15}
                              src={`${process.env.REACT_APP_BASE_ENDPOINT}/storage/${item.image}`}
                              alt={item.title}
                              mr="19px"
                            />
                          )}

                          <Text
                            bgClip="text"
                            color="white"
                            alignSelf="center"
                            fontSize={23}
                          >
                            {item.title}
                          </Text>
                        </Flex>
                      </Box>
                    </Link>
                  );
                })}
              </>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </Box>
    </div>
  );
};

export default SideCategories;
